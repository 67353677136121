<template>
  <div>
    <div id="myChart" :style="{ height: '300px' }"></div>
  </div>
</template>

<script>
export default {
  props: {
    lineChartLabel: {
      default: function () {
        return [];
      },
    },
    lineChartValueEntry: {
      default: function () {
        return [];
      },
    },
    lineChartValue: {
      default: function () {
        return [];
      },
    },
  },
  mounted() {
    this.initChart();
  },
  watch: {
    lineChartLabel: {
      handler(ne) {
        this.updatedData();
      },
    },
  },
  methods: {
    updatedData() {
      this.myChart.clear();
      this.myChart.setOption({
        tooltip: {
          trigger: "axis",
          formatter(params) {
            let tooltip = "";
            tooltip += params[0].axisValue + "</br>";
            params.forEach((e) => {
              tooltip += `${e.marker} ${e.seriesName}: ${e.value} 次` + "</br>";
            });
            return tooltip;
          },
        },
        legend: {
          data: ["入场次数", "出场次数"],
        },
        xAxis: {
          data: this.lineChartLabel,
        },
        yAxis: {
          name: "数量(次)",
        },
        series: [
          {
            name: "入场次数",
            type: "line",
            data: this.lineChartValueEntry,
          },
          {
            name: "出场次数",
            type: "line",
            data: this.lineChartValue,
          },
        ],
      });
    },
    initChart() {
      const myChart = this.$echarts.init(document.getElementById("myChart"));
      this.myChart = myChart;
      this.updatedData();
    },
  },
};
</script>
