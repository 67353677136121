<!--
 * @Author: wch
 * @Date: 2021-11-17 15:18:54
 * @LastEditTime: 2021-12-14 16:43:29
 * @LastEditors: your name
 * @Description: 
 * @FilePath: \aiparkcity-acb\src\views\parkingBerthTurnover\lineChart2.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div>
    <div id="trend" :style="{ height: '300px' }"></div>
  </div>
</template>

<script>
export default {
  props: {
    lineChartLabel: {
      default: function () {
        return [];
      },
    },
    lineChartValue: {
      default: function () {
        return [];
      },
    },
  },
  mounted() {
    this.initChart();
  },
  watch: {
    lineChartLabel: {
      handler(ne) {
        this.updatedData();
      },
    },
  },
  methods: {
    updatedData() {
      let that = this;
      this.myChart.clear();
      this.myChart.setOption({
        tooltip: {
          trigger: "axis",
          formatter(params) {
            let tooltip = "";
            tooltip += params[0].axisValue + "</br>";
            params.forEach((e) => {
              tooltip += `${e.marker} ${e.seriesName}: ${e.value} 次` + "</br>";
            });
            return tooltip;
          },
        },
        xAxis: {
          data: this.lineChartLabel,
        },
        yAxis: {
          name: "周转次数(次)",
        },
        series: [
          {
            name: "周转次数",
            type: "line",
            data: this.lineChartValue,
          },
        ],
      });
    },
    initChart() {
      const myChart = this.$echarts.init(document.getElementById("trend"));
      this.myChart = myChart;
      this.updatedData();
    },
  },
};
</script>
