<template>
  <div>
    <div class="content">
      <!--搜索条件区域-->
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form :inline="true" label-position="right" :model="formInline">
          <el-form-item :label="$t('searchModule.region')" prop="areaId">
            <a-cascader ref="cascader" @change="parkClear"></a-cascader>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Belonging_operator')">
            <a-operation-select ref="operationSelect" @change="parkClear"></a-operation-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Type_of_parking_lot')">
            <a-park-type-select ref="parkTypeSelect" @change="parkClear"></a-park-type-select>
          </el-form-item>
          <!-- <el-form-item :label="$t('searchModule.region')">
            <el-select v-model.trim="formInline.areaId"
                      @change='areaChange'
                      filterable
                      size="15"
                      placeholder="请选择">
              <el-option label="全部"
                        value=""></el-option>
              <el-option :label="area.areaName"
                        :value="area.areaId"
                        :key="area.areaId"
                        v-for="area in areaList"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.region')">
            <el-select v-model.trim="formInline.streetId"
                      @change="streetChange"
                      filterable
                      size="15"
                      placeholder="请选择">
              <el-option label="全部"
                        value=""></el-option>
              <el-option :label="area.areaName"
                        :value="area.areaId"
                        :key="area.areaId"
                        v-for="area in areaList2"></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item :label="$t('searchModule.Road_or_Parking_Lot')">
            <a-park-select
              ref="parkSelect"
              :instance="this"
              parkTypeRefName="parkTypeSelect"
              operationRefName="operationSelect"
              areaRefName="cascader"
            ></a-park-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Date_search')">
            <a-date-picker ref="datePicker" :selectkeys="selectkeys"></a-date-picker>
          </el-form-item>
          <!-- <el-form-item :label="$t('searchModule.Date_search')">
            <el-select @change='typeChange' v-model.trim="formInline.dateType" filterable>
              <el-option label="日" value="1"></el-option>
              <el-option label="月" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              :value-format="formInline.dateType == 1 ? 'yyyy-MM-dd' : 'yyyy-MM'"
              v-model="formInline.dateArray"
              :type="formInline.dateType == 1 ? 'daterange' : 'monthrange'"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item> -->
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="searchData()" :loading="loading"
              >{{ $t('button.search') }}</el-button
            >
            <el-button type="primary" icon="el-icon-refresh" @click="resetForm()" :loading="loading"
              >{{ $t('button.reset') }}</el-button
            >
            <el-button
              type="primary"
              :loading="loading"
              @click="exportFile"
              v-if="$route.meta.authority.button.export"
              >{{ $t('button.export') }}</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!-- 图表区域 -->
      <div class="chart-wrapper">
        <div class="graphShadow paddingT10 paddingLR20 marginTB20 paddingB10">
          <graphTitle :title="'进出场趋势'">
            <el-tooltip placement="top" content="展示统计时间段内的车辆入场与车辆出场次数变化趋势">
              <i class="el-icon-question my-icon" />
            </el-tooltip>
          </graphTitle>
          <line-chart
            :lineChartValueEntry="lineChartValueEntry"
            :lineChartLabel="lineChartLabel"
            :lineChartValue="lineChartValue"
          />
        </div>
        <div class="graphShadow paddingT10 paddingLR20 marginTB20 paddingB10">
          <graphTitle :title="'日均泊位周转趋势'">
            <el-tooltip
              placement="top"
              content="展示统计时间段内的泊位周转次数变化趋势. 泊位周转次数 = 统计时间段内的停车记录数 / (统计天数 * 总泊位数)"
            >
              <i class="el-icon-question my-icon" />
            </el-tooltip>
          </graphTitle>
          <line-chart2 :lineChartLabel="lineChartLabel" :lineChartValue="berthTurnoverRatioValue" />
        </div>
        <el-table :data="tableData_">
          <el-table-column label="时间" prop="_dataDate" align="center" />
          <el-table-column label="入场次数(次)" prop="parkingEntryCnt" align="center" />
          <el-table-column label="出场次数(次)" prop="parkingExitCnt" align="center" />
          <el-table-column label="总泊位数(个)" prop="berthAmount" align="center" />
          <el-table-column
            label="日均泊位周转次数(次)"
            prop="berthAvgTurnOverRatio"
            align="center"
          />
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import lineChart from "./lineChart";
import lineChart2 from "./lineChart2";
import graphTitle from "@/components/graphTitle";
import { exportExcelNew, checkDate, formatWeekDate } from "@/common/js/public.js";
import myComponent from "@/components/autocomplete/myautoComponent";

export default {
  components: {
    lineChart,
    lineChart2,
    graphTitle,
  },
  data() {
    let startDate = this.$moment().subtract(30, "days").format("YYYY-MM-DD");
    let endDate = this.$moment().subtract(1, "days").format("YYYY-MM-DD");
    return {
      selectkeys: ["date", "month", "week", "year"],
      areaList2: [],
      areaList: [],
      tableData_: [],
      lineChartValueEntry: [],
      pageNum: 1,
      pageSize: 15,
      loading: false,
      dateArray: [],
      modelvalue: "",
      formInline: {
        parkId: "",
        areaId: "",
        streetId: "",
        dateType: "1",
        dateArray: [startDate, endDate],
      },
      lineChartLabel: [],
      lineChartValue: [],
      berthTurnoverRatioValue: [],
      tableData: [],
    };
  },
  methods: {
    parkClear() {
      this.$refs.parkSelect.clear();
    },
    searchData() {
      this.loading = false;
      let { dateArray, ...data } = this.formInline;
      data.streetId = this.$refs.cascader ? this.$refs.cascader.getStreetId() : "";
      data.areaId = this.$refs.cascader ? this.$refs.cascader.getAreaId() : "";
      data.operationId = this.$refs.operationSelect
        ? this.$refs.operationSelect.getOperationId()
        : "";
      data.parkId = this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "";
      data.dateType = this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "";
      data.startDate = this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "";
      data.endDate = this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "";
      data.dataSource = this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "";
      // /acb/2.0/berthStatistics/berthTurnoverRatio 老接口
      this.$axios
        .get("/acb/2.0/aceBerth/berthTurnoverRatio", {
          data: {
            ...data,
          },
        })
        .then((res) => {
          let tableData = [];
          let lineChartLabel = [];
          let lineChartValue = [];
          let lineChartValueEntry = [];
          let berthTurnoverRatioValue = [];

          if (Array.isArray(res.value)) {
            res.value.forEach((v) => {
              let {
                dataDate,
                parkingEntryCnt,
                parkingExitCnt,
                berthAmount,
                berthAvgTurnOverRatio,
                berthUtilizationRatio,
              } = v;
              lineChartLabel.push(dataDate);
              lineChartValue.push(parkingExitCnt);
              lineChartValueEntry.push(parkingEntryCnt);
              berthTurnoverRatioValue.push(berthAvgTurnOverRatio);
            });
          }

          this.tableData = res.value || [];
          this.tableData.forEach((element) => {
            element.berthAvgTurnOverRatio = Number(element.berthAvgTurnOverRatio).toFixed(2);
          });
          this.lineChartLabel = lineChartLabel;
          console.log(this.lineChartLabel);
          this.lineChartValue = lineChartValue;
          this.lineChartValueEntry = lineChartValueEntry;
          // this.berthTurnoverRatioValue = berthTurnoverRatioValue;
          this.berthTurnoverRatioValue = [];
          berthTurnoverRatioValue.forEach((item) => {
            this.berthTurnoverRatioValue.push(Number(item).toFixed(2));
          });
          console.log(this.berthTurnoverRatioValue);
          // 周粒度需要单独处理

          this.tableData.forEach((item) => {
            if (this.$refs.datePicker.getDateType() == 3) {
              if (item.dataDate) {
                item._dataDate = formatWeekDate(item.dataDate);
              } else {
                item._dataDate = item.dataDate;
              }
            } else {
              item._dataDate = item.dataDate;
            }
          });
          this.tableData_ = [...this.tableData].reverse();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    typeChange(v) {
      if (v == 1) {
        let startDate = this.$moment().subtract(30, "days").format("YYYY-MM-DD");
        let endDate = this.$moment().subtract(1, "days").format("YYYY-MM-DD");
        this.formInline.dateArray = [startDate, endDate];
      } else if (v == 2) {
        let startDate = this.$moment().subtract(11, "months").format("YYYY-MM");
        let endDate = this.$moment().format("YYYY-MM");
        this.formInline.dateArray = [startDate, endDate];
      }
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    // 改变城市
    areaChange(item) {
      if (!item) {
        this.formInline.streetId = "";
        return;
      }
      this.resetCarPark();
      this.areaList2 = [];
      this.getChildrenArea(item);
    },
    getChildrenArea(item) {
      this.$axios
        .get("/acb/2.0/systems/loginUser/getChildrenArea", {
          data: {
            areaId: item,
          },
        })
        .then((res) => {
          this.areaList2 = res.value;
          this.formInline.streetId = "";
        });
    },
    // 改变区域
    streetChange(item) {
      this.resetCarPark();
    },
    // 清空车场组件信息
    resetCarPark() {
      this.formInline.parkId = "";
      this.formInline.parkName = "";
      // this.$refs.parkInput.setShowVal("");
    },
    // 清空
    resetForm() {
      this.formInline.areaId = "";
      this.formInline.streetId = "";
      this.formInline.dateType = "1";
      this.resetCarPark();
      this.typeChange(1);
      this.$refs.cascader.clear();
      this.$refs.operationSelect.clear();
      this.$refs.datePicker.clear();
      this.$refs.parkTypeSelect.clear();
      this.$refs.parkSelect.clear();
    },
    // 初始化用户区域下拉列表（区域和街道）
    getArea() {
      this.$axios.get("/acb/2.0/systems/loginUser/initAreaList").then((res) => {
        if (res.value.depth == 3) {
          this.areaList = res.value.areaList;
          this.formInline.areaId = "";
        } else if (res.value.depth == 4) {
          this.areaList2 = res.value.areaList;
          this.formInline.areaId = "";
        }
      });
    },
    handleSelect(item) {
      this.formInline.parkId = item.parkId;
    },
    exportFile() {
      // if (!this.checkDate()) return;
      let { dateArray, ...data } = this.formInline;
      data.parkId = this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "";
      data.streetId = this.$refs.cascader ? this.$refs.cascader.getStreetId() : "";
      data.areaId = this.$refs.cascader ? this.$refs.cascader.getAreaId() : "";
      data.operationId = this.$refs.operationSelect
        ? this.$refs.operationSelect.getOperationId()
        : "";
      data.dateType = this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "";
      data.startDate = this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "";
      data.endDate = this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "";
      data.dataSource = this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "";
      exportExcelNew("/acb/2.0/aceBerth/exportBerthTurnoverRatio", {
        ...data,
      });
    },
  },
  created() {
    this.getArea();
  },
  mounted() {
    this.searchData();
  },
};
</script>

<style lang="stylus" scoped>
.breadcrumb {
  height: 35px;
}

.content {
  overflow: hidden;

  .searchWrapper {
    .list {
      margin-top: 20px;

      .el-col {
        text-align: center;
        padding-bottom: 18px;

        .grid-content {
          padding-top: 15px;
          height: 100%;
        }

        h2 {
          font-size: 18px;
          text-align: center;
        }
      }
    }
  }
}
</style>
